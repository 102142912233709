import ApplyLoyaltyModal from "@/components/retail/pos/pointOfSale/posCart/Loyalty/Points/ApplyLoyaltyModal/ApplyLoyaltyModal.component";
import Template from "@/components/retail/pos/pointOfSale/posCart/Loyalty/Points/Points.template.vue";
import { loyaltyDiscountsCodes } from "@/enums/discountManager";
import { LoyaltyProgram } from "@/interfaces/loyaltyProgram";
import {
  AppliedDiscount,
  AvailableDiscount,
  Order,
  OrderItem
} from "@/interfaces/order";
import { messagesService } from "@/services/messages.service";
import { Callback } from "@/types/types";
import {
  LoyaltyConfig,
  LoyaltyRedemption
} from "@/vuex/modules/order/order.types";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import find from "lodash/find";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

const namespace: string = "OrderModule";

@Component({
  mixins: [Template],
  components: {
    ApplyLoyaltyModal
  }
})
export default class PointsComponent extends Vue {
  @Prop({ required: true })
  public order!: Order;
  @Prop({ required: true })
  public orderItem!: OrderItem;
  @Action("addDiscount", { namespace })
  public addDiscountAction!: Callback;
  @Getter("loyaltyConfig", { namespace })
  public loyaltyConfig!: LoyaltyConfig;
  public pointDiscount: AvailableDiscount | null = null;
  public isEdit = false;

  public points = 1;

  public amount = 1;
  public unitPayment = "$";
  public amountStrings: {} = {};

  public tab = 2;
  public pointBreaks: number[] = [];

  public programsWithRewards: LoyaltyProgram[] = [];

  public debouncePointsChange = debounce(async (scope: any) => {
    if (scope.points < scope.redemptionStep) {
      scope.points = scope.redemptionStep;
    }
    scope.amount = scope.points / scope.redemptionStep;
  }, 500);
  public debounceAmountChange = debounce(async (scope: any) => {
    if (scope.amount < 1) {
      scope.amount = 1;
    }
    scope.points = scope.amount * scope.redemptionStep;
  }, 500);

  public changePoints() {
    if (this.points && !/^[0-9]{0,7}?$/.test(String(this.points))) {
      this.points = parseInt(String(this.points), 10);
    }
    this.debouncePointsChange(this);
    this.checkLimits();
  }
  public changeAmount() {
    if (this.amount && !/^[0-9]{0,7}?$/.test(String(this.amount))) {
      this.amount = parseInt(String(this.amount), 10);
    }
    this.debounceAmountChange(this);
    this.checkLimits();
  }

  public mounted() {
    if (
      this.orderItem &&
      this.orderItem.prescription_details &&
      !this.orderItem!.prescription_details!.order_item_id
    ) {
      delete this.orderItem.prescription_details;
    }
    this.setPointBreaks();
    this.checkForApplied();
  }

  public checkLimits() {
    if (
      this.points > this.loyaltyRedemption.loyalty_points ||
      this.amount > this.loyaltyRedemption.redeem_value
    ) {
      messagesService.renderWarningMessage(
        "loyalty_points.override_points_available"
      );
      this.points = +this.loyaltyRedemption.loyalty_points;
      this.amount = +this.loyaltyRedemption.redeem_value;
    } else if (this.points < this.minPoints || this.amount < 1) {
      this.points = +this.minPoints;
      this.amount = +this.minPoints / +this.redemptionStep;
    }
  }
  public async openAddModal() {
    try {
      await this.$modals.load(
        ApplyLoyaltyModal,
        {
          size: "normal",
          positionX: "center",
          positionY: "center"
        },
        {
          order: cloneDeep(this.order),
          orderItem: cloneDeep(this.order.order_items),
          loyaltyRedemption: this.loyaltyRedemption,
          loyaltyConfig: this.loyaltyConfig,
          loyaltyRemaining: this.pointDiscount
        }
      );
    } catch (e) {
      // avoid uncaught rejection.
    }
  }

  public decreasePoints() {
    if (!this.disabled && !this.enforcePrograms) {
      this.points -= 1;
      this.amount -= +(1 / this.redemptionStep).toFixed(5);
      this.checkLimits();
    }
  }
  public increasePoints(points: number) {
    if (!this.disabled && !this.enforcePrograms) {
      if (points) {
        this.points = +this.points + points;
        this.amount = +this.amount + points / this.redemptionStep;
      } else {
        this.points = +this.points + 1;
        this.amount = this.points / this.redemptionStep;
      }
      this.checkLimits();
    }
  }
  public decreaseAmount() {
    if (!this.disabled && !this.enforcePrograms) {
      this.amount -= 1;
      this.points -= this.redemptionStep;
      this.checkLimits();
    }
  }
  public increaseAmount() {
    if (!this.disabled && !this.enforcePrograms) {
      this.amount = +this.amount + 1;
      this.points = +this.points + this.redemptionStep;
      this.checkLimits();
    }
  }

  public useAll() {
    this.points = this.loyaltyRedemption.loyalty_points;
    this.amount = this.loyaltyRedemption.redeem_value;
  }

  public notReady() {
    messagesService.renderWarningMessage("not_ready_yet");
  }

  public addPoints() {
    if (
      this.orderItem &&
      this.amount > Math.ceil(Number(this.orderItem.price_final))!
    ) {
      return messagesService.renderWarningMessage(
        "loyalty_points_exceed_order_item_total"
      );
    }
    if (!this.orderItem && this.amount > this.order.total!) {
      return messagesService.renderWarningMessage(
        "loyalty_points_exceed_order_total"
      );
    }
    const discount: { [key: string]: any } = {
      points: this.points,
      discount_id: (this.pointDiscount as AvailableDiscount).discount_id
    };
    if (this.orderItem) {
      discount.item_id = this.orderItem.id!;
    }
    this.addDiscountAction({
      discount: [discount],
      isEdit: this.isEdit
    });
    this.$emit("closePoints", this.orderItem);
  }

  public get enforcePrograms() {
    return this.loyaltyConfig.loyalty_point.enforce_loyalty_points || false;
  }
  public get disabled() {
    return (
      this.pointDiscount &&
      !this.pointDiscount.points &&
      (this.loyaltyRedemption && !this.loyaltyRedemption.loyalty_points)
    );
  }
  public get loyaltyRedemption(): LoyaltyRedemption {
    return this.loyaltyConfig.loyalty_redemption || 0;
  }
  public get minPoints() {
    return this.loyaltyConfig.loyalty_point.redemption_value_per_points || 0;
  }
  public get redemptionStep() {
    return (
      +this.minPoints /
        +this.loyaltyConfig.loyalty_point.redemption_value_dollars! || 1
    );
  }
  public get totalAmount() {
    return this.loyaltyRedemption.loyalty_points || 0;
  }

  public setPointBreaks() {
    if (this.order && this.order.available_discounts) {
      const loyaltyType =
        (this.orderItem && loyaltyDiscountsCodes.LOYALTY_PER_ITEM) ||
        loyaltyDiscountsCodes.LOYALTY_PER_ORDER;
      this.pointDiscount =
        find(this.order.available_discounts, (discount: AvailableDiscount) => {
          return discount.codename === loyaltyType;
        }) || null;
      this.pointDiscount!.points = this.order.loyalty_points;
    }
    if (this.loyaltyConfig) {
      this.points = +this.minPoints;
      this.debouncePointsChange(this);

      if (this.pointBreaks.length) {
        this.pointBreaks = [];
      }
      if (10 < this.totalAmount) {
        this.pointBreaks.push(10);
      }
      if (100 < this.totalAmount) {
        this.pointBreaks.push(100);
      }
      if (1000 < this.totalAmount) {
        this.pointBreaks.push(1000);
      }
    }
  }

  public checkForApplied() {
    let found: AppliedDiscount | undefined;
    if (this.order.applied_discounts) {
      found = this.order.applied_discounts.find(discount => {
        if (!this.orderItem) {
          return discount.discount_id === this.pointDiscount!.discount_id;
        } else {
          return (
            discount.discount_id === this.pointDiscount!.discount_id &&
            discount.item_id === this.orderItem.id
          );
        }
      });
    }

    if (found) {
      this.isEdit = true;
      this.points = found.points;
      this.amount = found.amount;
    }
  }
}
