import PosCartComponent from "@/components/retail/pos/pointOfSale/posCart/PosCart.component";
import ProductsSelect from "@/components/retail/pos/pointOfSale/posCart/ProductsSelect/ProductsSelect.component";
import PosCustomerListComponent from "@/components/retail/pos/pointOfSale/posCustomerList/PosCustomerList.component";
import PosModalActiveCarComponent from "@/components/retail/pos/pointOfSale/posModalActiveCar/PosModalActiveCar.component";
import PosProductCardComponent from "@/components/retail/pos/pointOfSale/posProductCard/PosProductCard.component";
import PosProductListComponent from "@/components/retail/pos/pointOfSale/posProductList/PosProductList.component";
import PosProductModalComponent from "@/components/retail/pos/pointOfSale/posProductList/posProductModal/PosProductModal.component";
import AlertComponent from "@/components/sharedComponents/alert/alert.component";
import TraceabilityConfirm from "@/components/sharedComponents/traceabilityConfirm/TraceabilityConfirm.component";
import { ResetService } from "@/decorators/resetService.decorator";
import { pusherEvents } from "@/enums/pusherEvents";
import { Batch, InventoryBatch } from "@/interfaces/batch";
import { Brand } from "@/interfaces/brand";
import { Customer } from "@/interfaces/customer";
import { Discount } from "@/interfaces/discount";
import { JournalNotes } from "@/interfaces/journalNotes";
import { Location } from "@/interfaces/location";
import { Modal } from "@/interfaces/modal";
import {
  BiotrackTraceNotification,
  PusherNotification
} from "@/interfaces/notification";
import {
  AddToCartPayload,
  AvailableDiscount,
  AvailableDiscountGroup,
  AvailableProgramDiscount,
  Order,
  OrderItem,
  statusOrder
} from "@/interfaces/order";
import { PharmacistInterface } from "@/interfaces/pharmacist";
import { defaultPrescriptionDetails } from "@/interfaces/prescriptionDetails";
import { Product } from "@/interfaces/product";
import { ProductCategory } from "@/interfaces/productCategoy";
import { RetailSettings } from "@/interfaces/retailSettings";
import { User } from "@/interfaces/user";
import { EventBus } from "@/internal";
import { customerService } from "@/services/customer.service";
import { discountManagerService } from "@/services/discountManager.service";
import { messagesService } from "@/services/messages.service";
import { orderService } from "@/services/order.service";
import { pharmacistService } from "@/services/pharmacist.service";
import { productService } from "@/services/product.service";
import { Callback, CallbackPromise, PageNavAction } from "@/types/types";
import { getParentSKU } from "@/utils/batch-actions.utils";
import { preOrderItemPrescriptionDetail } from "@/vuex/modules/order/order.actions";
import { LoyaltyConfig } from "@/vuex/modules/order/order.types";
import { compareAsc } from "date-fns";
import Cookie from "js-cookie";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import find from "lodash/find";

import { TranslateResult } from "vue-i18n";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./PointOfSale.template.vue";
import PosModalPreOrderChangesComponent from "./posModalPreOrderChanges/PosModalPreOrderChanges.component";
import SelectCaregiverOrder from "./selectCaregiverOrder/SelectCaregiverOrder.component";

const namespace: string = "OrderModule";
const findTypeProfile = (customer: Customer, type: string) => {
  return customer && find(customer.profiles, ["profile_type", type]);
};

@Component({
  mixins: [Template],
  components: {
    PosCartComponent,
    PosProductListComponent,
    PosProductCardComponent,
    PosCustomerListComponent,
    PosModalActiveCarComponent
  },
  inject: ["$changes"]
})
@ResetService([orderService, productService])
export default class PointOfSaleComponent extends Vue {
  public get activeProduct() {
    return !!this.messageCustomer;
  }
  @Getter("products", { namespace: "ProductModule" })
  public products!: Product[];
  @Action("loadProducts", { namespace: "ProductModule" })
  public getProductsAction!: Callback;
  @Action("setHeaders", { namespace: "ProductModule" })
  public setHeadersAction!: Callback;
  @Action("searchProducts", { namespace: "ProductModule" })
  public searchProducts!: Callback;
  @Action("getDiscounts", { namespace })
  public getDiscounts!: CallbackPromise<void>;
  @Action("resetState", { namespace })
  public resetState!: Callback;
  @Getter("loading", { namespace: "ProductModule" })
  public loadingProducts!: boolean;

  @Getter("productCategories", { namespace: "ProductModule" })
  public productCategories!: ProductCategory[];
  @Getter("brands", { namespace: "ProductModule" })
  public brands!: Brand[];

  @Action("loadOrder", { namespace })
  public loadOrder!: CallbackPromise<undefined>;
  @Action("addOrderItem", { namespace })
  public addOrderItemAction!: (items: OrderItem[]) => Promise<boolean>;
  @Action("addProgramDiscount", { namespace })
  public addProgramDiscount!: CallbackPromise<undefined>;
  @Action("getLoyaltyPrograms", { namespace })
  public getLoyaltyPrograms!: CallbackPromise<undefined>;
  @Getter("loyaltyConfig", { namespace })
  public loyaltyConfig!: LoyaltyConfig;
  @Getter("loading", { namespace })
  public loadingOrder!: boolean;

  @Getter("discounts", { namespace })
  public discounts!: Discount[];
  @Action("addDiscount", { namespace })
  public addDiscountAction!: Callback;
  @Getter("order", { namespace })
  public order!: Order;
  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public currentRetailSettings!: RetailSettings;
  @Getter("user", { namespace: "AuthModule" })
  public currentLoggedUser!: User;
  @Action("customerLimits", { namespace })
  public customerLimits!: CallbackPromise<any>;
  @Action("deleteOrder", { namespace })
  public deleteOrder!: CallbackPromise<any>;
  @Getter("currentCustomer", { namespace: "CustomerModule" })
  public currentCustomer!: Customer;
  @Action("setCustomer", { namespace: "CustomerModule" })
  public setCustomer!: Callback;
  @Action("findCustomerProfile", { namespace: "CustomerModule" })
  public findCustomerProfile!: CallbackPromise<void>;
  @Action("updateCustomer", { namespace })
  public updateCustomer!: CallbackPromise<void>;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  @Action("savePrescriptionDetail", { namespace })
  public savePrescriptionDetail!: Callback;
  @Action("setSelectedPharmacistOnDuty", { namespace })
  public setSelectedPharmacistOnDuty!: Callback;
  @Action("findOrder", { namespace })
  public findOrder!: Callback;
  @Getter("hasBioTrackTraceIntegrations", { namespace: "AuthModule" })
  public hasBioTrackTraceIntegrations!: boolean;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;

  public pharmacistSelected: number | null = null;
  public pharmacistItems: Array<{ text: string; value: number }> = [];
  public categorySelected: number[] = [];
  public brandSelected: number[] = [];
  public value: string = "";
  public delayTimer: any;
  public listView: boolean = false;
  public patientList: Customer[] = [];
  public messageCustomer: string | TranslateResult = "";
  public statusModal: boolean = false;
  public lastCancelShown = "";

  public debounceTime = 800;
  public modalData: Modal = { title: null, width: "358px", height: "174px" };

  public filterProductsPerCategory = debounce(async (scope: any) => {
    const queryfilter: { [key: string]: string } = {
      route: "on_sale"
    };
    if (scope.categorySelected) {
      queryfilter.product_category = scope.categorySelected;
    }
    if (scope.brandSelected) {
      queryfilter.brand = scope.brandSelected;
    }
    scope.searchProducts(queryfilter);
  }, this.debounceTime);

  public productSearch = debounce(async (value: string) => {
    this.categorySelected = [];
    this.brandSelected = [];
    if (!value || value.length) {
      if (value.indexOf("DISC") < 0) {
        const queryfilter = {
          value,
          "batches.batch_uid": true,
          "batches.biotrack_traceability_id": true,
          "batches.tracking_id": true,
          "batches.product_variant.external_batch_number": true,
          barcode: true,
          is_batch_search: true,
          route: "on_sale",
          byText: true
        };
        this.searchProducts(queryfilter);
      }
    }
  }, this.debounceTime);

  public assignSelectedPharmacist() {
    this.changePrescriptionDetailPharmacist();
    this.setSelectedPharmacistOnDuty(this.pharmacistSelected);
    Cookie.set("pos_pharmacist", String(this.pharmacistSelected), {
      domain: `${process.env.VUE_APP_COOKIE_DOMAIN}`
    });
  }

  public async changePrescriptionDetailPharmacist() {
    if (this.order.order_items && this.order.order_items.length) {
      const filteredOrderItem = this.order.order_items.filter(
        item =>
          !item.has_preorder_item &&
          item.product_info!.marijuana &&
          !this.availablePrescriptionDetail(item)
      );

      if (filteredOrderItem.length) {
        filteredOrderItem.forEach(orderItem => {
          const orderItemIndex = this.order.order_items!.findIndex(
            item => item.id === orderItem.id
          );

          if (!this.order.order_items![orderItemIndex].prescription_details) {
            this.order.order_items![
              orderItemIndex
            ].prescription_details = defaultPrescriptionDetails;
          }
          this.order.order_items![
            orderItemIndex
          ].prescription_details!.pharmacist_id = this.pharmacistSelected;
        });
      }
    }
  }

  public availablePrescriptionDetail(item: OrderItem) {
    const prescriptionData = item.prescription_details;
    if (
      prescriptionData &&
      prescriptionData.quantity &&
      prescriptionData.quantity_unit_measure &&
      prescriptionData.timeframe_unit_measure &&
      prescriptionData.dosage_unit_measure &&
      prescriptionData.days_supply &&
      prescriptionData.dosage_take &&
      prescriptionData.dosage_to &&
      prescriptionData.dosage_timeframe_take &&
      prescriptionData.dosage_timeframe_to &&
      prescriptionData.pharmacist_id &&
      prescriptionData.doctor_id
    ) {
      return true;
    } else {
      return false;
    }
  }

  public filterProductsPerValue() {
    this.productSearch(this.value);
  }

  public findBySelecteds() {
    this.filterProductsPerCategory(this);
  }
  public async modalProductBarcodeScan(product: Product) {
    try {
      const addItem = await this.$modals.load<AddToCartPayload>(
        PosProductModalComponent,
        {
          size: "normal",
          positionY: "top"
        },
        {
          product: await productService.setExpandedProductView(product),
          pharmacistSelected: this.pharmacistSelected
        }
      );

      if (addItem) {
        this.addItemToCart(addItem);
      }
    } catch {
      //
    }
  }

  public async onBarcodeScanned(barcode: string) {
    if (barcode.indexOf("DISC") === 0) {
      const discountBarcode: string = barcode.split("DISC")[1].trim();
      if (discountBarcode) {
        this.searchDiscount(discountBarcode);
      }
    } else {
      const results = await productService.getProductScan(barcode.trim());

      if (results && results.products.length) {
        const product: Product = results.products[0];
        product.batches = Object.values(product.batches);

        if (
          this.currentCustomer &&
          !this.currentCustomer.member_level_id &&
          product.price_settings.members_only_product
        ) {
          EventBus.$emit("modal", {
            msgModal: {
              title: "",
              body: this.$t("member_only_modal").toString(),
              captionButton: "OK"
            }
          });
        } else {
          this.setProductToBeAdded(barcode, product);
        }
      } else {
        messagesService.renderWarningMessage(
          (results && `scan_status.${results.status}`) || "pos_scan_404"
        );
      }
    }
  }

  public async setProductToBeAdded(barcode: string, product: Product) {
    let batch:
      | InventoryBatch
      | undefined = (product.batches as InventoryBatch[]).find(batchItem => {
      return (
        batchItem.batch_uid === barcode || batchItem.tracking_id === barcode
      );
    });
    if (!batch) {
      if (
        this.currentRetailSettings.oldest_batch_first &&
        product.batches.length > 1
      ) {
        batch = (product.batches as InventoryBatch[]).sort(
          (b: InventoryBatch, b1: InventoryBatch) =>
            compareAsc(new Date(b.created_at), new Date(b1.created_at))
        )[0];
      }
      batch = product.batches[0] as InventoryBatch;
    }
    const orderItem = {
      quantity: 1,
      batch_sku: batch!.sku!,
      batch_uid: batch!.batch_uid,
      batch_barcode_uid: batch!.batch_uid
    };
    if (
      this.currentRetailSettings.auto_add_item_scanner &&
      this.currentCustomer
    ) {
      await this.addOrderItemAction([orderItem]);
      this.value = "";
      this.getProductsAction("on_sale");
    } else if (batch) {
      if (
        this.hasBioTrackTraceIntegrations &&
        this.bioTrackTraceEnabled &&
        product.marijuana
      ) {
        if (batch.batch_uid && batch.biotrack_traceability_id) {
          this.modalProductBarcodeScan({ ...product, batches: [batch] });
        } else {
          await productService.renderSearchErrorModal(
            "biotrack_traceability.search_error",
            "biotrack_traceability.search_error_message",
            "",
            true,
            false,
            "ok",
            "biotrack_traceability.retail_pointOfSale",
            "/img/icon_primary_menu_retail@2x.009e06e8.png"
          );
        }
      } else {
        this.modalProductBarcodeScan({ ...product, batches: [batch] });
      }
      this.value = barcode;
    }
  }

  public async searchDiscount(discountBarcode: string) {
    try {
      const discounts: Discount[] =
        (this.discounts.length && this.discounts) ||
        (await discountManagerService.getDiscounts(true, {
          no_pagination: true
        }));

      const discount: Discount | undefined = discounts.find(
        discountItem => discountItem!.external_barcode === discountBarcode
      );

      if (discount) {
        this.applyBarcodeDiscount(discount);
      } else {
        messagesService.renderWarningMessage(
          "cart_discounts.barcode_without_discount"
        );
      }
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  public modalDiscounts(
    availableDiscounts: AvailableDiscount[],
    discount: Discount
  ) {
    const items: number[] = availableDiscounts.reduce(
      (acc: number[], discountItem: AvailableDiscount) => {
        acc = [...acc, ...discountItem.items_ids!];
        return acc;
      },
      []
    );
    const group: AvailableDiscountGroup = {
      discount,
      items
    };
    this.$modals
      .load(
        ProductsSelect,
        {
          size: "normal"
        },
        {
          group,
          orderItems: cloneDeep(this.order.order_items)
        }
      )
      .then(
        (resp: any) =>
          this.addDiscountAction({
            discount: resp
          }),
        () => {
          // Modal close
        }
      );
  }

  public async caregiverCheck() {
    this.messageCustomer = "";
    if (this.currentLocation.location_type === "RETAIL_MEDICAL") {
      await this.setPatientList();
      if (this.isCaregiver) {
        this.messageCustomer =
          this.isCaregiver && !this.isPatient && this.patientList.length
            ? "customer.msg_caregiver_no_patient"
            : !this.isPatient
            ? "customer.no_patient_profiles"
            : "";
        if (this.messageCustomer) {
          this.selectCaregiverOder();
        }
      }
    }
  }

  public async setPatientList() {
    if (!this.patientList.length && this.isCaregiver) {
      const caregiverProfile = findTypeProfile(
        this.currentCustomer.purchasingBy || this.currentCustomer,
        "CAREGIVER"
      );
      if (caregiverProfile) {
        this.patientList = await customerService.findPatients(
          this.currentCustomer.customer_id!
        );
      }
    }
  }

  public showMessage(message: string) {
    this.$modals
      .load(
        PosModalActiveCarComponent,
        {
          size: "fit"
        },
        {
          message: this.$t(message)
        }
      )
      .catch();
  }

  /**
   * batch validation that is not in a room, will jump notification
   */

  public NotificationBatchRoom(product: Product) {
    if (
      !(product.batches as InventoryBatch[]).find(
        batchList => batchList.batch_uid === this.value
      )
    ) {
      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        String(
          this.$t("batch_transfer_manager_module.messages.batch_not_room")
        ),
        "warning"
      );
    }
  }

  @Watch("products")
  public loadedProduct() {
    if (
      this.products.length === 1 &&
      this.value &&
      this.value.substring(0, 3) === "BID"
    ) {
      this.NotificationBatchRoom(this.products[0]);
    }
  }

  public toggleProductsView() {
    this.listView = !this.listView;
  }

  public loadProductsEvent() {
    this.getProductsAction("on_sale");
  }

  public async addItemToCart(item: AddToCartPayload) {
    let actionSuccess;
    if (item.loyalty_program_tier_id) {
      const program: AvailableProgramDiscount[] = item.orderItem.map(
        orderItem => {
          return {
            sku: orderItem.batch_sku! || orderItem.sku,
            loyalty_program_tier_id: item.loyalty_program_tier_id!,
            pretax_price: orderItem.prices![0].pre_excise,
            quantity: orderItem.quantity!,
            batch_barcode_uid: orderItem.batch_barcode_uid!,
            prescription_details: orderItem.prescription_details,
            pharmacist_id: orderItem.pharmacist_id
          };
        }
      );
      actionSuccess = await this.addProgramDiscount({
        program
      });
    } else {
      actionSuccess = await this.addOrderItemAction(item.orderItem);
    }
    if (actionSuccess) {
      this.getProductsAction("on_sale");
    }
  }

  public async statusCustomer() {
    const customerId: string =
      (this.currentCustomer.purchasingBy &&
        this.currentCustomer.purchasingBy.customer_id) ||
      this.currentCustomer.customer_id!;
    await customerService.checkInCustomer(customerId);
  }

  public deleteCart() {
    this.deleteOrder();
  }

  public async setUpCustomer(customer: Customer, orderExists = false) {
    if (!customer.profiles) {
      await this.findCustomerProfile({
        id: customer.customer_id,
        redirect: false
      });
    } else {
      this.setCustomer(customer);
    }
    // Update customer status
    if (
      (this.currentCustomer.purchasingBy &&
        this.currentCustomer.purchasingBy.serving_number &&
        this.currentCustomer.purchasingBy.serving_number.status ===
          "CHECKED_IN") ||
      (this.currentCustomer.serving_number &&
        this.currentCustomer.serving_number.status === "CHECKED_IN")
    ) {
      this.setCustomerAsAttended();
    }
    // Caregiver Messages
    await this.caregiverCheck();
    // Check for pending orders
    if (this.isPatient) {
      const previousOrders = orderExists ? [this.order] : customer.orders || [];
      this.updateCustomer({ ...this.currentCustomer, orders: previousOrders });
    }
    this.getLoyaltyPrograms();
    this.loadProductsEvent();
    // using customerJournalNotes as static data once BE API ready will change accordingly

    const response = await customerService.getJournalsPopUpModel(
      customer.customer_id!,
      {
        currentPage: 1,
        itemsPerPage: 10,
        totalItems: 0,
        itemsPerPageOptions: [],
        from: 0,
        to: 0
      },
      ["POPUP_MODAL_NOTE_FLAG"]
    );

    const customerJournalNotes: JournalNotes[] = response.data.filter(
      (singleNote: JournalNotes) =>
        singleNote.priority === "POPUP_MODAL_NOTE_FLAG"
    );

    if (customerJournalNotes.length) {
      customerJournalNotes.map(singleNote => {
        (singleNote.status = {
          name: singleNote.title,
          style: "fontSize:22px ; fontWeight:600;"
        }),
          (singleNote.description = {
            name: singleNote.note,
            style: "fontSize:20px ; textAlign:left"
          });
      });

      if (customer) {
        await this.renderTraceabilityModal(
          "",
          "",
          "",
          true,
          false,
          "ok",
          customerJournalNotes,
          customer.customer_id!
        );
      }
    }
  }

  @Watch("order", { deep: true })
  public preOrderChangesModal() {
    if (
      this.order &&
      this.lastCancelShown !== this.order.order_number &&
      this.order.pre_order &&
      this.order.pre_order.items &&
      this.order.pre_order.items.find(item => {
        return item.updated === true || item.canceled === true;
      })
    ) {
      this.lastCancelShown = this.order.order_number!;
      this.$modals
        .load(
          PosModalPreOrderChangesComponent,
          { size: "normal", positionX: "center", positionY: "center" },
          { preOrder: cloneDeep(this.order.pre_order) }
        )
        .then(() => false)
        .catch(() => false);
    }
  }

  public async renderTraceabilityModal(
    statusName: string,
    descriptionName: string,
    loader: string,
    accept: boolean,
    cancel: boolean,
    acceptValue: string,
    customerJournalNotes: JournalNotes[],
    id: string
  ) {
    this.$modals.load(
      TraceabilityConfirm,
      {
        size: "normal",
        positionX: "center",
        positionY: "center",
        // @ts-ignore
        style: "max-height:680px"
      },
      {
        modalData: {
          justifyContent: "justify-start",
          status: {
            name: statusName,
            style: "fontSize:23px ; fontWeight:600;"
          },

          description: {
            name: descriptionName,
            style: "fontSize:20px ; textAlign:left"
          },

          title: {
            name: this.$t("biotrack_traceability.retail_customer_note_title"),
            style: "fontSize:27px ; letter-spacing: 0px;"
          },
          titleAvatar: {
            name: "/img/icon_primary_menu_retail@2x.009e06e8.png",
            size: 80
          },
          loading: this.$t(loader),
          acceptButton: accept,
          cancelButton: cancel,
          acceptButtonValue: acceptValue,
          customerJournalNotes,
          id
        }
      }
    );
  }

  public async changeCustomer(customer: Customer) {
    if (!this.currentCustomer) {
      this.setUpCustomer(customer);
      return;
    }

    if (
      this.currentCustomer &&
      this.currentCustomer.customer_id !== customer.customer_id
    ) {
      if (this.order.id) {
        await this.showCustomerChangeAlert();
      }
      this.setUpCustomer(customer);
    } else if (
      this.currentCustomer &&
      this.currentCustomer.customer_id === customer.customer_id
    ) {
      if (this.currentCustomer.purchasingBy && customer.purchasingBy) {
        if (
          this.currentCustomer.purchasingBy.customer_id !==
          customer.purchasingBy.customer_id
        ) {
          if (this.order.id) {
            await this.showCustomerChangeAlert();
          }
          this.setUpCustomer(customer);
        }
      } else {
        if (this.order.id) {
          await this.showCustomerChangeAlert();
        }
        this.setUpCustomer(customer);
      }
    }
  }

  public async setCustomerAsAttended() {
    const customerUpdated = await customerService.customerAtended(
      (this.currentCustomer.purchasingBy &&
        this.currentCustomer.purchasingBy.customer_id!) ||
        this.currentCustomer.customer_id!
    );
    if (customerUpdated) {
      this.setCustomer({
        ...this.currentCustomer,
        serving_number: customerUpdated.serving_number
      });
    }
  }

  public showCustomerChangeAlert(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.$modals
        .loadConfirmation({
          title: "new_customer",
          text: "save_cart_switching",
          acceptButton: "yes",
          cancelButton: "no"
        })
        .then(async (confirm: boolean) => {
          if (confirm) {
            await orderService.saveOrder(Number(this.order.id));
          } else {
            if (this.order.pre_order) {
              await orderService.unloadPreorder(
                this.order.pre_order!.uid,
                false
              );
            } else {
              this.deleteCart();
            }
          }
          resolve();
        }, reject);
    });
  }

  public async selectCaregiverOder() {
    this.$modals
      .load<Order>(
        SelectCaregiverOrder,
        {
          size: "fit"
        },
        {
          patients: this.patientList,
          customer: cloneDeep(this.currentCustomer)
        }
      )
      .then(
        async (order: Order) => {
          this.messageCustomer = "";
          if (order.customer!.caregiver && !order.customer!.purchasingBy) {
            order.customer!.purchasingBy = this.currentCustomer;
          }
          this.setCustomer(order.customer!);
          this.orderLoadProcess(order);
        },
        () => {
          // No action needed.
        }
      );
  }

  public async orderLoadProcess(orderToSet: Order) {
    if (orderToSet) {
      // To save Prescription detail for Pre Order either it's Pending or Marked as Filled
      if (
        orderToSet &&
        orderToSet.order_items &&
        orderToSet.pre_order &&
        orderToSet.pre_order.status !== "LOADED" &&
        orderToSet.pre_order.status !== "CANCELED"
      ) {
        for (const preOrderItem of orderToSet.pre_order.items) {
          if (preOrderItem.filled && preOrderItem.filled.length) {
            orderToSet!.order_items = await preOrderItemPrescriptionDetail({
              orderItems: orderToSet!.order_items,
              preOrders: preOrderItem.filled
            });
          } else {
            orderToSet!.order_items = await preOrderItemPrescriptionDetail({
              orderItems: orderToSet!.order_items,
              preOrders: preOrderItem.ordered
            });
          }
        }
      }

      /**In order to use a saved order, it must be restored and
       * so it changes to status pending to be able to display
       */

      if (orderToSet.id && orderToSet.status === statusOrder.saved) {
        await orderService.restoreOrder(orderToSet.id);
      }

      /**
       * Tells the API that the order selected comes from a pre-order
       * so that it can transition the pre-order
       */
      if (
        orderToSet.pre_order &&
        orderToSet.pre_order.status !== "LOADED" &&
        orderToSet.pre_order.status !== "CANCELED"
      ) {
        const preOrderUpdated = await orderService.restorePreOrder(
          orderToSet.pre_order.uid
        );
        if (preOrderUpdated) {
          orderToSet.pre_order.status = preOrderUpdated.status;
        }
      }
      this.findOrder(orderToSet);
    }
  }

  public get orderItemsIds() {
    const orderItems = cloneDeep(this.order.order_items) || [];
    return orderItems.reduce((acc: string[], item) => {
      const productSku = getParentSKU(item.sku!);
      if (!acc.includes(productSku)) {
        acc.push(productSku);
      }

      return acc;
    }, []);
  }

  public get enableLoyalty() {
    return (
      this.order.customer_id &&
      this.loyaltyConfig &&
      this.loyaltyConfig.loyalty_point &&
      this.loyaltyConfig.loyalty_point.enable_loyalty_points
    );
  }

  public get isCaregiver() {
    return (
      !!(this.currentCustomer && this.currentCustomer.purchasingBy) ||
      !!findTypeProfile(this.currentCustomer, "CAREGIVER")
    );
  }

  public get isPatient() {
    return (
      this.currentCustomer &&
      this.currentCustomer.profiles &&
      !!findTypeProfile(this.currentCustomer, "PATIENT")
    );
  }

  protected applyBarcodeDiscount(discount: Discount) {
    const availableDiscounts: AvailableDiscount[] = this.order.available_discounts!.filter(
      availableDisc =>
        availableDisc.discount_id === discount.id && !availableDisc.auto_apply
    );
    const availableDiscountsLength = availableDiscounts.length;
    if (availableDiscountsLength) {
      if (availableDiscountsLength === 1) {
        const discountToApply: AvailableDiscount = availableDiscounts[0];
        this.addDiscountAction({
          discount: [discountToApply]
        });
      } else {
        this.modalDiscounts(availableDiscounts, discount);
      }
    } else {
      messagesService.renderWarningMessage(
        "cart_discounts.discount_not_available"
      );
    }
  }

  protected async created() {
    if (
      this.currentRetailSettings.pharmacy_mode_enabled &&
      !this.currentRetailSettings.rx_number
    ) {
      await this.$modals
        .load(
          AlertComponent,
          {
            size: "fit"
          },
          {
            msgModal: {
              title: this.$t("pmp.rxNumber_warn_title"),
              body: this.$t("pmp.rxNumber_warn_message"),
              captionButton: "OK"
            }
          }
        )
        .then(
          () => {
            this.$router.back();
          },
          () => {
            this.$router.back();
          }
        );
    }
  }

  protected async mounted() {
    if (this.currentRetailSettings.pharmacy_mode_enabled) {
      const assignedPharmacistUserPayload =
        Cookie.get("pos_pharmacist") || null;

      const pharmacistList = await pharmacistService.getAll();
      this.pharmacistItems = pharmacistList.map(
        (item: PharmacistInterface) => ({
          text: `${item.pharmacist_first_name}  ${item.pharmacist_last_name} ${
            item.pharmacist_title ? ", " + item.pharmacist_title : ""
          } `,
          value: Number(item.user_id)
        })
      );

      if (
        assignedPharmacistUserPayload &&
        assignedPharmacistUserPayload !== "undefined"
      ) {
        this.pharmacistSelected = Number(assignedPharmacistUserPayload) || 0;
      } else {
        const index = pharmacistList.findIndex(
          item => item.user_id === this.currentLoggedUser.id
        );
        if (index !== -1) {
          this.pharmacistSelected = pharmacistList[index].user_id || 0;
          Cookie.set("pos_pharmacist", String(this.pharmacistSelected), {
            domain: `${process.env.VUE_APP_COOKIE_DOMAIN}`
          });
        }
      }
      this.setSelectedPharmacistOnDuty(this.pharmacistSelected);
    }

    this.$changes.do(pusherEvents.customer, {
      callback: async (data: PusherNotification) => {
        const pusherEvent = data.message[0];
        const customerUpdated = pusherEvent.payload as Customer;
        if (
          pusherEvent.action !== "CustomerCheckOut" &&
          pusherEvent.action !== "CustomerCheckIn" &&
          customerUpdated.customer_id === this.currentCustomer.customer_id
        ) {
          this.setCustomer(
            await customerService.findById(this.currentCustomer.customer_id!, {
              embed: "notes,coupons,profiles,balance,servingNumber"
            })
          );
          this.findOrder(this.order);
        }
      },
      displayForCurrentUser: true
    });

    this.setPageNav({ title: "point_sale" });
    orderService.resetPagination();
    orderService.resetQuery();
    this.getDiscounts();
    await this.setHeadersAction("on_sale");
    // @ts-ignore
    this.$barcodeScanner.init(this.onBarcodeScanned);
    this.getProductsAction("on_sale");
    await this.loadOrder().then(() => {
      if (
        this.order &&
        this.order.pre_order &&
        this.order.pre_order.confirmed_at === null
      ) {
        this.setCustomer(this.order.customer);
        this.customerLimits();
      } else {
        this.setCustomer(null);
        if (this.order.customer) {
          this.setUpCustomer(this.order.customer, true);
        }
      }
    });

    EventBus.$on("open-modal-patient", this.selectCaregiverOder);
  }
  protected beforeDestroy() {
    EventBus.$off("open-modal-patient");
    // @ts-ignore
    this.$barcodeScanner.destroy();
    if (this.order && !this.order.order_items!.length) {
      this.resetState();
    }
  }
}
